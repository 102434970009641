import axios from "axios";

const TOKEN = "token";
const REFRESH_TOKEN = "refreshToken";
const CURR_USER = "currUser";

const EMPTY_BODY = {};

const API_ENDPOINT = "https://sweep-backend-2024.oa.r.appspot.com/rest/";

const AUTH_ENDPOINT = API_ENDPOINT + "auth/";
const REFRESH_TOKEN_ENDPOINT = AUTH_ENDPOINT + "refresh/";
const VERIFY_EMAIL_ENDPOINT = AUTH_ENDPOINT + "verification/";
const LOGIN_ENDPOINT = AUTH_ENDPOINT + "login/";
const REGISTER_ENDPOINT = AUTH_ENDPOINT + "register/";
const MFA_ENDPOINT = LOGIN_ENDPOINT + "mfa/";

//Util functions

async function getToken() {
  const currentToken = localStorage.getItem(TOKEN);

  return currentToken;
}

function getRefreshToken() {
  // if(!currentRefreshToken){
  const currentRefreshToken = localStorage.getItem(REFRESH_TOKEN);
  // }
  return currentRefreshToken;
}

async function refreshToken() {
  const token = await getToken();
  const refreshToken = getRefreshToken();

  const headers = {
    Authorization: token,
  };

  try {
    const res = await axios.post(
      REFRESH_TOKEN_ENDPOINT,
      { refreshToken: refreshToken },
      { headers: headers }
    );

    saveTokens(res.data.token, res.data.refreshToken);
  } catch (e) {
    if (e.response.status == 401) {
      if (token == (await getToken())) {
        clearTokens();
      }
    }
  }
}

function saveTokens(token, refreshToken) {
  // currentToken = token;
  // currentRefreshToken = refreshToken;
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

function clearTokens() {
  // currentToken = null;
  // currentRefreshToken = null;
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  window.location.reload();
}

function clearCurrentUser() {
  localStorage.removeItem(CURR_USER);
}

async function retry(promiseFunction) {
  try {
    const token = await getToken();
    return await promiseFunction(token);
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await refreshToken();
      const token = await getToken();
      return await promiseFunction(token);
    }
    throw e;
  }
}

//Auth class

class Auth {
  //TODO: Add the rest of the auth methods

  verifyEmail(verId, secret) {
    return axios.post(VERIFY_EMAIL_ENDPOINT + verId, {
      secret: secret,
    });
  }

  async login(identifier, password,tfaCode = "") {
    try {

      let body = {
        identifier: identifier,
        password: password,
      }

      if(tfaCode){
        body.mfaCode = tfaCode;
      }

      const res = await axios.post(LOGIN_ENDPOINT, body);

      if (res.status != 200) {
        return res.data.error;
      }

      saveTokens(res.data.token, res.data.refreshToken);
      //console.log(res.data.token);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async logout() {
    const token = await getToken();
    clearTokens();
    clearCurrentUser();

    try {
      const res = await axios.delete(LOGIN_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
        },
      });

      return res.data;
    } catch (e) {}
  }

  register(username, name, email, password) {
    return axios.post(REGISTER_ENDPOINT, {
      username: username,
      name: name,
      email: email,
      password: password,
      sendVerification: true,
    });
  }

  generateNewMFA(){
    return retry((token) =>
      axios.post(MFA_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async verifyMFA(code){
    return retry((token) =>
      axios.put(MFA_ENDPOINT, {
        mfaCode: code
      }, {
        headers: {
          Authorization: token,
       
        },
      })
    );
  }

  async disableMFA(code){

    return retry((token) =>
      axios.delete(MFA_ENDPOINT, {
        headers: {
          Authorization: token,
        },
        data:{
          mfaCode: code
        }
      })
    );
  }


}

const USERS_ENDPOINT = API_ENDPOINT + "users/";
const CURRENT_USER_ENDPOINT = USERS_ENDPOINT + "current";
const USER_COMMUNITIES_ENDPOINT = USERS_ENDPOINT + "communities";
const USER_REPORTS_ENDPOINT = USERS_ENDPOINT + "reports";
const LIST_USERS_ENDPOINT = USERS_ENDPOINT + "listAll";
const LIST_USERS_PAG_ENDPOINT = USERS_ENDPOINT + "listAllPag";
const LIST_USERS_NAME_ENDPOINT = USERS_ENDPOINT + "listName";
const BLOCK_USER_ENDPOINT = USERS_ENDPOINT + "block";
const DELETE_USER_ENDPOINT = USERS_ENDPOINT + "delete";
const UPDATE_USER_ENDPOINT = USERS_ENDPOINT + "update";
const CHANGE_USER_PASSWORD_ENDPOINT = USERS_ENDPOINT + "changePassword";
const CHANGE_ROLE_ENDPOINT = USERS_ENDPOINT + "changeRole";

const GET_PARTNER_ENDPOINT = USERS_ENDPOINT + "partner";
const LIST_PARTNER_REQUESTS_ENDPOINT = USERS_ENDPOINT + "listPartnerRequests";
const LIST_PARTNERS_ENDPOINT = USERS_ENDPOINT + "listPartners";
const ACCEPT_PARTNER_ENDPOINT = USERS_ENDPOINT + "acceptPartnerAccount";
const DECLINE_PARTNER_ENDPOINT = USERS_ENDPOINT + "declinePartnerAccount";


class Users {
  async getCurrentUser() {
    return retry((token) =>
      axios.get(CURRENT_USER_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getUserCommunities() {
    return retry((token) =>
      axios.get(USER_COMMUNITIES_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getUserReports() {
    return retry((token) =>
      axios.get(USER_REPORTS_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getAllUsers() {
    return retry((token) =>
      axios.get(LIST_USERS_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getAllUsersWithPagination(page = 1, limit = 8) {
    return retry((token) =>
      axios.get(LIST_USERS_PAG_ENDPOINT, {
        headers: {
          Authorization: token,
        },
        params: {
          page:page,
          limit: limit,
        },
      })
    );
  }

  async searchByName(search) {
    return retry((token) =>
      axios.get(LIST_USERS_NAME_ENDPOINT, {
        headers: {
          Authorization: token,
          Name: search
        }
      })
    );
  }


  async blockUser(userId, blocked) {
    const url = `${BLOCK_USER_ENDPOINT}?UserId=${userId}&Block=${blocked}`;
    return retry((token) =>
      axios.put(url, EMPTY_BODY, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async deleteUser(userId) {
    const url = `${DELETE_USER_ENDPOINT}?UserId=${userId}`;
    return retry((token) =>
      axios.delete(url, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async updateUser(userData) {
    return retry((token) =>
      axios.put(UPDATE_USER_ENDPOINT, userData, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async changeUserPassword(changePasswordData) {
    return retry((token) =>
      axios.put(CHANGE_USER_PASSWORD_ENDPOINT, changePasswordData, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async changeRole(userId, newRole) {

    return retry((token) =>
      axios.put(CHANGE_ROLE_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          UserId: userId,
          Role: newRole,
        },
      })
    );
  }

  async listPartnerRequests() {

    return retry((token) =>
      axios.get(LIST_PARTNER_REQUESTS_ENDPOINT , {
        headers: {
          Authorization: token,
        },
      })
    );
  }


  async getPartner(partnerId) {
    return retry((token) =>
      axios.get(GET_PARTNER_ENDPOINT, {
        headers: {
          Authorization: token,
          partnerId: partnerId
        },
      })
    );
  }

  async listPartners() {

    return retry((token) =>
      axios.get(LIST_PARTNERS_ENDPOINT , {
        headers: {
          Authorization: token,
          Type:"All"
        },
      })
    );
  }


  async acceptPartner(userId) {

    return retry((token) =>
      axios.put(ACCEPT_PARTNER_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          UserId: userId,
        },
      })
    );
  }

  async declinePartner(userId) {

    return retry((token) =>
      axios.put(DECLINE_PARTNER_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          UserId: userId,
        },
      })
    );
  }


}

const COMMUNITY_ENDPOINT = API_ENDPOINT + "community/";
const COMMUNITY_USERS_ENDPOINT = COMMUNITY_ENDPOINT + "users";
const ALL_COMMUNITIES_ENDPOINT = COMMUNITY_ENDPOINT + "list";
const LIST_COMMUNITIES_ENDPOINT = COMMUNITY_ENDPOINT + "listPag";
const REMOVE_COMMUNITY_USER_ENDPOINT = COMMUNITY_ENDPOINT + "remove";
const ADD_COMMUNITY_USER_ENDPOINT = COMMUNITY_ENDPOINT + "joinBO";
const DELETE_COMMUNITY_ENDPOINT = COMMUNITY_ENDPOINT + "delete";
const UPDATE_COMMUNITY_ENDPOINT = COMMUNITY_ENDPOINT + "update";
const PROMOTE_COMMUNITY_USER_ENDPOINT = COMMUNITY_ENDPOINT + "promote";

class Communities {
  async createCommunity(communityData) {
    return retry((token) =>
      axios.post(COMMUNITY_ENDPOINT, communityData, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async updateCommunity(communityData) {
    return retry((token) =>
      axios.put(UPDATE_COMMUNITY_ENDPOINT, communityData, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getCommunityUsers(communityId) {
    return retry((token) =>
      axios.get(COMMUNITY_USERS_ENDPOINT, {
        headers: {
          Authorization: token,
          CommunityId: communityId,
        },
      })
    );
  }

  async getAllCommunities() {
    return retry((token) =>
      axios.get(ALL_COMMUNITIES_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getAllCommunitiesWithPagination(page = 1, limit = 8) {
    return retry((token) =>
      axios.get(LIST_COMMUNITIES_ENDPOINT, {
        headers: {
          Authorization: token,
        },
        params: {
          page: page,
          limit: limit,
        },
      })
    );
  }

  async removeUserFromCommunity(communityId, userId) {
    return retry((token) =>
      axios.post(REMOVE_COMMUNITY_USER_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          CommunityId: communityId,
          UserId: userId,
        },
      })
    );
  }

  async addUserToCommunity(communityId, userId) {
    return retry((token) =>
      axios.post(ADD_COMMUNITY_USER_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          CommunityId: communityId,
          UserId: userId,
        },
      })
    );
  }

  async deleteCommunity(communityId) {
    const url = `${DELETE_COMMUNITY_ENDPOINT}?CommunityId=${communityId}`;
    return retry((token) =>
      axios.delete(url, {
        headers: {
          Authorization: token,
        },
      })
    );
  }


  async promote (userId, communityId) {
    return retry((token) =>
      axios.post(PROMOTE_COMMUNITY_USER_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          UserId: userId,
          CommunityId: communityId,
        },
      })
    );
  
  }
}

const REPORT_ENDPOINT = API_ENDPOINT + "report/";
const CLEAN_REPORT_ENDPOINT = REPORT_ENDPOINT + "state";
const CREATE_REPORT_ENDPOINT = REPORT_ENDPOINT + "create";
const REPORTS_TO_CLEAN_ENDPOINT = REPORT_ENDPOINT + "toClean";
const ALL_REPORTS_ENDPOINT = REPORT_ENDPOINT + "listAll";

class Reports {
  async createReport(reportData) {
    return retry((token) =>
      axios.post(CREATE_REPORT_ENDPOINT, reportData, {
        headers: {
          Authorization: token,
        },
      })
    );
  }


  async getReportsToClean() {
    return retry((token) =>
      axios.get(REPORTS_TO_CLEAN_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async getAllReports() {
    return retry((token) =>
      axios.get(ALL_REPORTS_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }


  async cleanReport(reportId) {
  
    return retry((token) =>
      axios.put(CLEAN_REPORT_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          reportId: reportId,
        },
      })
    );
  }

  async deleteReport(reportId) {
    return retry((token) =>
      axios.delete(REPORT_ENDPOINT, {
        headers: {
          Authorization: token,
          reportId: reportId,
        },
      })
    );
  }
}


const EVENTS_ENDPOINT = API_ENDPOINT + "event/";
const USER_EVENTS_ENDPOINT = EVENTS_ENDPOINT + "user";
const CONCLUDED_EVENTS_ENDPOINT = EVENTS_ENDPOINT + "concludedBO";

class Events {
  async getEvents() {
    return retry((token) =>
      axios.get(EVENTS_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async createEvent(eventData) {
    return retry((token) =>
      axios.post(EVENTS_ENDPOINT, eventData, {
        headers: {
          Authorization: token,
        },
      })
    );
  }

  async deleteEvent(eventId) {
    return retry((token) =>
      axios.delete(EVENTS_ENDPOINT, {
        headers: {
          Authorization: token,
          EventId: eventId,
        },
      })
    );
  }

  async getUserEvents() {
    return retry((token) =>
      axios.get(USER_EVENTS_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }


  async finishEvent(points,eventId) {
    return retry((token) =>
      axios.put(CONCLUDED_EVENTS_ENDPOINT, EMPTY_BODY, {
        headers: {
          Authorization: token,
          points: points,
          eventId: eventId,
        },
      })
    );
  }


}

const COUPONS_ENDPOINT = API_ENDPOINT + "coupons/";
const COUPONS_LIST_ENDPOINT = COUPONS_ENDPOINT + "list";


class Coupons{
    async getCoupons() {
      return retry((token) =>
        axios.get(COUPONS_LIST_ENDPOINT, {
          headers: {
            Authorization: token,
          },
        })
      );
    }

    async createCoupon(couponData) {
      return retry((token) =>
        axios.post(COUPONS_ENDPOINT, couponData, {
          headers: {
            Authorization: token,
          },
        })
      );
    }

    async deleteCoupon(couponId) {
      return retry((token) =>
        axios.delete(COUPONS_ENDPOINT + couponId, {
          headers: {
            Authorization: token,
          },
        })
      );
    }
}




const STATS_ENDPOINT = API_ENDPOINT + "stats/";

class Stats {
  async getStats() {
    return retry((token) =>
      axios.get(STATS_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }
}

const IMAGE_ENDPOINT = API_ENDPOINT + "image/";
const GET_IMAGE_UPLOAD_URL_ENDPOINT = IMAGE_ENDPOINT + "getUploadURL";
class Images {

  async upload(image) {

    

    //upload the image to the URL
    const response = await retry((token) =>
      axios.get(GET_IMAGE_UPLOAD_URL_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );

    const uploadURL = response.data.uploadURL;

    //return the URL of the image

    await axios.put(uploadURL, image,{
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });

    return uploadURL.split('?')[0];
  }


}

const MAPS_ENDPOINT = API_ENDPOINT + "maps/";

class Maps {

  async getCoupons() {
    return retry((token) =>
      axios.get(COUPONS_LIST_ENDPOINT, {
        headers: {
          Authorization: token,
        },
      })
    );
  }  
}

export { Auth, Users, Communities, Reports,Events, Coupons,Stats,Images,Maps};
